import React, { useState } from "react";
import mammoth from 'mammoth';
import parse from 'html-react-parser';
import LogoIMG from '../../assets/img/logo.svg';
import _ from 'lodash';

let languagesData = {
  start: 0,
  end: 0,
  isEnd: false,
  data: [],
};

export const CV = () => {
  const [html, setHtml] = useState('');

  let paragraphNumber = 0;

  const summaryData = {
    start: 0,
    end: 0,
    isEnd: false,
  };

  const educationData = {
    start: 0,
    end: 0,
    isEnd: false,
  };

  const projectExperienceData = {
    start: 0,
    end: 0,
    isEnd: false,
    periodRowNum: 0,
    technologiesRowNum: 0,
    roleRowNum: 0,
    dutiesRowNum: 0,
  }

  const skillsData = {
    start: 0,
  }

  function transformParagraph(element) {
    const value = element?.children?.map((elem) => elem?.children?.[0]?.value).join('');
    const childrenType = element?.children?.[0]?.children?.[0]?.type;

    if (childrenType === 'image') {
      paragraphNumber = paragraphNumber + 1;
      return {
        ...element,
        children: [
          {
            ...element.children[0],
            children: [
              {
                ...element.children[0].children[0],
              }
            ],
          }
        ],
        styleName: 'imageWrapper',
        styleId: "Heading2"
      }
    }

    if (value) {
      paragraphNumber = paragraphNumber + 1;

      // Header --------------------------------------------------------------------
      if (paragraphNumber === 1) {
        return {
          ...element,
          styleId: "Heading1",
          styleName: 'title',
        }
      }

      if (paragraphNumber === 2 && value !== 'Personal information' && value !== 'Summary') {
        return {
          ...element,
          styleName: 'subtitle',
        }
      }

      if (value.includes('Location')) {
        return {
          ...element,
          children: [
            {
              "type": "run",
              "children": [
                {
                  "type": "text",
                  "value": value.replace('Location',''),
                }
              ],
              "styleId": null,
              "styleName": null,
              "isBold": false,
              "isUnderline": false,
              "isItalic": false,
              "isStrikethrough": false,
              "isAllCaps": false,
              "isSmallCaps": false,
              "verticalAlignment": "baseline",
              "font": null,
              "fontSize": null,
            }
          ],
          styleName: 'location',
        }
      }
    
      // Summary --------------------------------------------------------------------
      if (!summaryData.isEnd && value !== 'Education') {
        if (!summaryData.start) summaryData.start = paragraphNumber;
        summaryData.end = paragraphNumber;
      } else {
        summaryData.isEnd = true;
      }

      if (value === 'Personal information' || value === 'Summary') {
        return {
          ...element,
          children: [
            {
              "type": "run",
              "children": [
                {
                  "type": "text",
                  "value": 'Summary',
                }
              ],
              "styleId": null,
              "styleName": null,
              "isBold": false,
              "isUnderline": false,
              "isItalic": false,
              "isStrikethrough": false,
              "isAllCaps": false,
              "isSmallCaps": false,
              "verticalAlignment": "baseline",
              "font": null,
              "fontSize": null,
            }
          ],
          styleId: "Heading2",
          styleName: "summary",
        }
      }

      if (!summaryData.isEnd) {
        return {
          type: 'text',
          value: value + ' ',
        }
      }

      // Education --------------------------------------------------------------------
      if (!educationData.isEnd && value !== 'Languages') {
        if (!educationData.start) {
          educationData.start = paragraphNumber;
        }
        educationData.end = paragraphNumber;
      } else {
        educationData.isEnd = true;
      }

      if (value === 'Education') {
        return {
          ...element,
          styleId: "Heading2",
        }
      }

      if (!educationData.isEnd) {
        return {
          ...element,
          type: 'paragraph',
          styleName: 'educationParagraph',
        }
      }


      // languages --------------------------------------------------------------------
      if (
        !languagesData.isEnd && 
        value !== 'Project experience' &&
        value !== 'Projects experience' &&
        value !== 'Projects'
      ) {
        
        if (!languagesData.start) {
          languagesData.start = paragraphNumber;
        }
        languagesData.end = paragraphNumber;

        if (value === 'Languages') {
          languagesData.data.push({
            ...element,
            styleId: "Heading2",
          });

          return {
            type: 'text',
            value: '',
          }
          
        }
  
        if (!languagesData.isEnd) {
          languagesData.data.push({
            ...element,
            styleName: "languageRow",
          });

          return {
            type: 'text',
            value: '',
          }
        }

        return {
          type: 'text',
          value: '',
        }

      } else if (languagesData.start && !languagesData.isEnd) {
        languagesData.isEnd = true;
        projectExperienceData.start = paragraphNumber;

        return {
          "type": "paragraph",
          "children": languagesData.data,
          "styleId": null,
          "styleName": 'languagesSection',
          "alignment": null,
          "indent": {
            start: null,
            end: null,
            hanging: null,
            firstLine: null,
          },
          "numbering": {
            isOrdered: false,
            level: 0,
            paragraphStyleId: undefined,
          },
        };
      }

      // Project Experince --------------------------------------------------------------------

      if (projectExperienceData.start && !projectExperienceData.end) {

        projectExperienceData.end = paragraphNumber;

        return {
          "type": "run",
          "children": [
            {
              "type": "paragraph",
              "children": [
                {
                  "type": "run",
                  "children": [
                    {
                      "type": "text",
                      "value": 'Projects',
                    }
                  ],
                  "styleId": null,
                  "styleName": null,
                  "isBold": false,
                  "isUnderline": false,
                  "isItalic": false,
                  "isStrikethrough": false,
                  "isAllCaps": false,
                  "isSmallCaps": false,
                  "verticalAlignment": "baseline",
                  "font": null,
                  "fontSize": null,
                }
              ],
              "styleId": "Heading1",
              "styleName": 'projects',
              "isBold": false,
              "alignment": null,
              "indent": {
                start: null,
                end: null,
                hanging: null,
                firstLine: null,
              },
              "numbering": {
                isOrdered: false,
                level: 0,
                paragraphStyleId: undefined,
              },
            },
            {
              ...element
            }
          ],
          "styleId": null,
          "styleName": 'projectsSection',
          "alignment": null,
          "indent": {
            start: null,
            end: null,
            hanging: null,
            firstLine: null,
          },
          "numbering": {
            isOrdered: false,
            level: 0,
            paragraphStyleId: undefined,
          },
        };

      }
      
      if (
        !projectExperienceData.isEnd &&
        value !== 'Project experience' &&
        value !== 'Projects experience'
      ) {
        if (!projectExperienceData.start) {
          projectExperienceData.start = paragraphNumber;
        }
        projectExperienceData.end = paragraphNumber;
      } else {
        projectExperienceData.isEnd = true;
      }

      

      if (value === 'Period') {
        projectExperienceData.periodRowNum = paragraphNumber + 1;

        return {
          type: 'text',
          value: '',
        }
      }

      // strong text for project period
      if (projectExperienceData.periodRowNum && projectExperienceData.periodRowNum === paragraphNumber) {
        return {
          ...element,
          children: [
            ...element.children.map((elem) => {
              return {
                ...elem,
                isBold: true,
              }
            }),
          ],
          styleName: 'projectPeriod',
        }
      }

      if (value === 'Description') {
        return {
          type: 'text',
          value: '',
        }
      }

      if (value === 'Technologies') {
        projectExperienceData.technologiesRowNum = paragraphNumber + 1;
        return {
          ...element,
          styleName: 'projectTechnologies',
        }
      }

      if (
        projectExperienceData.technologiesRowNum &&
        projectExperienceData.technologiesRowNum === paragraphNumber
      ) {
        const technologiesArray = value.split(',');
        const technologiesParagraphs = technologiesArray.map((elem) => {
          return {
            "type": "paragraph",
            "children": [
              {
                "type": "run",
                "children": [
                  {
                    "type": "text",
                    "value": elem,
                  }
                ],
                "styleId": null,
                "styleName": null,
                "isBold": false,
                "isUnderline": false,
                "isItalic": false,
                "isStrikethrough": false,
                "isAllCaps": false,
                "isSmallCaps": false,
                "verticalAlignment": "baseline",
                "font": null,
                "fontSize": null,
              }
            ],
            "styleId": null,
            "styleName": null,
            "alignment": null,
            "indent": {
              start: 720,
              end: null,
              hanging: 360,
              firstLine: null,
            },
            "numbering": {
              isOrdered: false,
              level: 0,
              paragraphStyleId: undefined,
            },
          }
        });

        return {
          "type": "run",
          "children": technologiesParagraphs,
          "styleId": null,
          "styleName": null,
          "alignment": null,
          "indent": {
            start: null,
            end: null,
            hanging: null,
            firstLine: null,
          },
          "numbering": null,
        }
      }


      if (value === 'Role' || value === 'Roles') {
        projectExperienceData.roleRowNum = paragraphNumber + 1;
        return {
          ...element,
          styleName: 'projectRoles',
        }
      }

      if (
        projectExperienceData.roleRowNum &&
        projectExperienceData.roleRowNum === paragraphNumber
      ) {
        const rolesArray = value.split(',');
        const rolesParagraphs = rolesArray.map((elem) => {
          return {
            "type": "paragraph",
            "children": [
              {
                "type": "run",
                "children": [
                  {
                    "type": "text",
                    "value": elem
                  }
                ],
                "styleId": null,
                "styleName": null,
                "isBold": false,
                "isUnderline": false,
                "isItalic": false,
                "isStrikethrough": false,
                "isAllCaps": false,
                "isSmallCaps": false,
                "verticalAlignment": "baseline",
                "font": null,
                "fontSize": null
              }
            ],
            "styleId": null,
            "styleName": null,
            "alignment": null,
            "indent": {
              start: 720,
              end: null,
              hanging: 360,
              firstLine: null,
            },
            "numbering": {
              isOrdered: false,
              level: 0,
              paragraphStyleId: undefined,
            },
          }
        });

        return {
          "type": "run",
          "children": rolesParagraphs,
          "styleId": null,
          "styleName": null,
          "alignment": null,
          "indent": {
            start: null,
            end: null,
            hanging: null,
            firstLine: null,
          },
          "numbering": null,
        }

      }

      if (value === 'Duties' || value === 'Resposibilities') {
        projectExperienceData.dutiesRowNum = paragraphNumber + 1;
        return {
          ...element,
          children: [
            {
              ...element.children[0],
              children: [
                {
                  ...element.children[0].children[0],
                  value: 'Resposibilities',
                }
              ]
            }
          ],
          styleName: 'projectResposibilities',
        }
      }

      if (
        projectExperienceData.dutiesRowNum &&
        projectExperienceData.dutiesRowNum === paragraphNumber
      ) {
        const dutiesArray = value.split(',');
        const dutiesParagraphs = dutiesArray.map((elem) => {
          return {
            "type": "paragraph",
            "children": [
              {
                "type": "run",
                "children": [
                  {
                    "type": "text",
                    "value": elem,
                  }
                ],
                "styleId": null,
                "styleName": null,
                "isBold": false,
                "isUnderline": false,
                "isItalic": false,
                "isStrikethrough": false,
                "isAllCaps": false,
                "isSmallCaps": false,
                "verticalAlignment": "baseline",
                "font": null,
                "fontSize": null
              }
            ],
            "styleId": null,
            "styleName": null,
            "alignment": null,
            "indent": {
              start: 720,
              end: null,
              hanging: 360,
              firstLine: null,
            },
            "numbering": {
              isOrdered: false,
              level: 0,
              paragraphStyleId: undefined,
            },
          }
        });

        return {
          "type": "run",
          "children": dutiesParagraphs,
          "styleId": null,
          "styleName": null,
          "alignment": null,
          "indent": {
            start: null,
            end: null,
            hanging: null,
            firstLine: null,
          },
          "numbering": null,
        }
      }

      if (value === 'Professional skills' || value === 'Skills') {
        skillsData.start = paragraphNumber;

        return {
          ...element,
          children: [
            {
              "type": "text",
              "value": 'Skills',
            }
          ],
          styleId: "Heading1",
          styleName: 'skills',
        }
      }

    }

    if (element.alignment === "center" && !element.styleId) {
      return {...element, styleId: "Heading3"};
    }

    return element
    
  }

  const transformElement = (element) => {

    if (element.children) {
      let children = _.map(element.children, transformElement);
      element = {...element, children: children};
    }
    
    if (
      element.type === "paragraph"
    ) {
      return transformParagraph(element);
    } else if (
      element.type === "table"
    ) {

      if (educationData.start && !educationData.isEnd) {
        const tableRows = element.children.map((tableRow) => {
          const paragraphs = mammoth.transforms.getDescendantsOfType(tableRow, 'paragraph')

          return {
            "type": "paragraph",
            "children": _.map(paragraphs, transformParagraph),
            "styleId": null,
            "styleName": 'educationSection',
            "alignment": null,
            "indent": {
              start: null,
              end: null,
              hanging: null,
              firstLine: null,
            },
            "numbering": null,
          };
        });

        return {
          "type": "paragraph",
          "children": tableRows,
          "styleId": null,
          "styleName": null,
          "alignment": null,
          "indent": {
            start: null,
            end: null,
            hanging: null,
            firstLine: null,
          },
          "numbering": null,
        }
      }

      if (!skillsData.start) {
        const paragraphs = mammoth.transforms.getDescendantsOfType(element, 'paragraph')
  
        return {
          "type": "run",
          "children": _.map(paragraphs, transformParagraph),
          "styleId": null,
          "styleName": null,
          "alignment": null,
          "indent": {
            start: null,
            end: null,
            hanging: null,
            firstLine: null,
          },
          "numbering": null,
        };
      } else {
        return {
          ...element,
          styleName: 'skillsTable',
        }
      }
    }

    return element
  }


  const options = {
    transformDocument: transformElement,
    styleMap: [ 
      "table[style-name='skillsTable'] => table.skillsTable",
      "p[style-name='educationDate'] => p.educationDate",
      "p[style-name='educationParagraph'] => p.educationParagraph:fresh",
      "p[style-name='imageWrapper'] => p.imageWrapper:fresh",
      "p[style-name='title'] => h1.title:fresh",
      "p[style-name='subtitle'] => p.subtitle:fresh",
      "p[style-name='location'] => p.location:fresh",
      "p[style-name='summary'] => h2.summary:fresh",
      "p[style-name='languageRow'] => p.languageRow:fresh",
      "p[style-name='projects'] => h1.projects:fresh",
      "p[style-name='skills'] => h1.skills:fresh",
      "p[style-name='languagesSection'] => div.languagesSection:fresh",
      "p[style-name='projectPeriod'] => p.projectPeriod:fresh",
      "p[style-name='projectTechnologies'] => p.projectTechnologies:fresh",
      "p[style-name='projectRoles'] => p.projectRoles:fresh",
      "p[style-name='projectResposibilities'] => p.projectResposibilities:fresh",
      "p[style-name='educationSection'] => div.educationSection:fresh",
    ]
  };

  const parseWordDocxFile = (inputElement) => {
    languagesData = {
      start: 0,
      end: 0,
      isEnd: false,
      data: [],
    };

    let files = inputElement.target?.files || [];
    if (!files.length) return;
    var file = files[0];
  
    console.time();
    let reader = new FileReader();
  
    reader.onloadend = function(event) {
      let arrayBuffer = reader.result;
  
      mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, options).then(
        function (result) {
          console.log(languagesData);
          setHtml(result.value) 
        }
      )
    };
    
    reader.readAsArrayBuffer(file);
  }

  const parseForPfdPrintFormat = (html) => {
    const parsedHtml = parse(html);

    return parsedHtml;
  }


  return (
    <>
      <div className="input-container">
        <input type="file" onChange={parseWordDocxFile}></input>
      </div>
      <div className="cv">
        <header className="cv__header">
          <img className="cv__header-logo" src={LogoIMG} alt="logo" />
        </header>
        { parseForPfdPrintFormat(html) }
      </div>
    </>
  );
};

