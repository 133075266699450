import React from "react";
import { CV } from "./CV/CV";
import './App.scss';

const App = () => {
  return <>
    <CV />
  </>;
}

export default App;
